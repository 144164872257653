export default {
  getStarted: {
    next: 'Next',
    previous: 'Previous',
    exploreNow: 'Explore Now',
    step1: {
      title: 'Welcome to Injective Hub',
      description: 'Your gateway to everything Injective.',
      section1Title: 'Create a Wallet',
      section1Description: 'Securely set up your key to the ecosystem.',
      section2Title: 'Get INJ',
      section2Description: 'Power your experience with the native asset.',
      section3Title: 'Stake & Earn',
      section3Description: 'Put your INJ to work and secure the network.',
      section4Title: 'Explore dApps',
      section4Description: 'Dive into decentralized innovation.'
    },
    step2: {
      title: 'Create a wallet',
      description:
        'Setting up your wallet is easy. Simply connect with a popular wallet like MetaMask, Keplr, Phantom or Ledger, and an Injective wallet address will automatically be created and linked to your account.',
      section1Title: 'Keplr',
      section1Description: 'The most popular wallet',
      section2Title: 'Metamask',
      section2Description: 'The original wallet you know and trust',
      section3Title: 'Google',
      section3Description: 'Use your email to connect instantly'
    },
    step3: {
      title: 'Get INJ',
      description:
        "To interact with Injective, you'll need INJ in your Injective wallet. You can get INJ on all major exchanges.",
      section1Title: 'Binance',
      section1Description: '(Ethereum ERC-20, Injective)',
      section2Title: 'Coinbase',
      section2Description: '(Ethereum ERC-20, Injective)',
      section3Title: 'Kraken',
      section3Description: '(Ethereum ERC-20, Injective)'
    },
    step4: {
      title: 'Stake & Earn',
      description:
        'Generate rewards while safeguarding Injective through its proof-of-stake system.'
    },
    step5: {
      title: 'Explore dApps',
      description:
        'From trading and staking to innovative DeFi products, Injective hosts the best dApps designed to empower your financial journey.',
      section1Title: 'Helix',
      section1Description: 'The best Dex spot and derivatives exchange',
      section2Title: 'Hydro Protocol',
      section2Description: 'Offers liquidity solutions',
      section3Title: 'Mito',
      section3Description: 'Revolutionize launchpads'
    }
  }
}
