<template>
  <div class="spa-loading-spinner">
    <AssetLogoMini class="logo-mini h-8 w-8" />
  </div>
</template>

<style scoped>
.spa-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spa-loading-spinner::after {
  content: '';
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  border: 2.8px solid #fff;
  border-radius: 50%;
  animation: loading-spinner 1s linear infinite;
  border-color: #fff #fff #fff transparent;
}

@keyframes loading-spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
