import { BigNumberInBase } from '@injectivelabs/utils'
import { Delegation, TokenStatic } from '@injectivelabs/sdk-ts'
import {
  ValidatorWithUptimeDetails,
  UnbondingsAndRedelegationsWithStatus
} from '@/types'

export enum ProposalVoterTableColumn {
  Voters = 'voters',
  Vote = 'vote'
}

export enum ProposalDepositorTableColumn {
  Depositors = 'depositors',
  Amount = 'amount'
}

export enum BurnAuctionHistoryTableColumn {
  Auction = 'auction',
  AmountBurnt = 'amount-burnt',
  Winner = 'winner',
  Action = 'action'
}

export enum AuctionCurrentRoundTableColumn {
  Token = 'token',
  Amount = 'amount',
  EstValue = 'est-value'
}

export enum ValidatorDelegationsTableColumn {
  Delegator = 'delegator',
  Percentage = 'percentage',
  StakedAmount = 'staked-amount',
  CommissionRate = 'commission-rate'
}

export enum TransactionsTableColumn {
  TxHash = 'tx-hash',
  Result = 'result',
  EventType = 'event-type',
  Block = 'block',
  Amount = 'amount',
  Time = 'time'
}

export enum StakeOverviewValidatorsTableColumn {
  Validator = 'validator',
  StakedAmount = 'staked-amount',
  Commission = 'commission',
  VotingPower = 'voting-power',
  Uptime = 'uptime',
  Action = 'action'
}

export enum UnstakeRestakeTableColumn {
  Delegations = 'delegations',
  Amount = 'amount',
  Status = 'status',
  CompletionTime = 'completion-time',
  Action = 'action'
}

export enum MyStakingTableColumn {
  Delegations = 'delegations',
  Commission = 'commission',
  StakedAmount = 'staked-amount',
  Claimable = 'claimable',
  Action = 'action'
}

export enum WalletTableColumn {
  Asset = 'asset',
  Price = 'price',
  Balance = 'balance',
  TotalValueUsd = 'total-value-usd',
  Action = 'action'
}

export interface UTableColumn {
  key: string
  label?: string
  class?: string
}

export interface TransformedAuctionCurrentRound {
  token: TokenStatic
  amount: BigNumberInBase
  amountInInj: BigNumberInBase
  amountInUsd: BigNumberInBase
}

export interface TransformedUnstakeRestake {
  completedAt: string
  isUnbonding: boolean
  completedAtFormatted: string
  delegatedAmount: BigNumberInBase
  formattedValidatorAddress: string
  validator: ValidatorWithUptimeDetails
  unbondingRedelegation: UnbondingsAndRedelegationsWithStatus
}

export interface TransformedMyStaking {
  delegation: Delegation
  stakingReward: BigNumberInBase
  commissionRate: BigNumberInBase
  delegatedAmount: BigNumberInBase
  formattedValidatorAddress: string
  validator: ValidatorWithUptimeDetails
}
