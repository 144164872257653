<script lang="ts" setup>
import {
  Status,
  StatusType,
  BigNumberInBase,
  DEFAULT_BLOCK_TIMEOUT_HEIGHT
} from '@injectivelabs/utils'
import { msgBroadcaster } from '@shared/WalletService'
import { Modal } from '@/types'

const modalStore = useSharedModalStore()
const sharedWalletStore = useSharedWalletStore()
const notificationStore = useSharedNotificationStore()
const { t } = useLang()
const { resetForm } = useForm()
const { $onError } = useNuxtApp()

const status = reactive(new Status(StatusType.Idle))

const { value: injectiveAddress, errors: injectiveAddressErrors } =
  useStringField({
    name: 'injAddress',
    rule: 'required|injAddress'
  })

const { value: txTimeout, errors: txTimeoutErrors } = useNumberField({
  name: 'txTimeout',
  initialValue: DEFAULT_BLOCK_TIMEOUT_HEIGHT,
  rule: `required|minValue:${DEFAULT_BLOCK_TIMEOUT_HEIGHT}`
})

function closeModal() {
  modalStore.closeModal(Modal.DevMode)
}

function connect() {
  status.setLoading()

  sharedWalletStore
    .connectAddress(injectiveAddress.value)
    .then(() => {
      notificationStore.success({ title: t('wallet.successfullyConnected') })
    })
    .catch($onError)
    .finally(() => {
      status.setIdle()

      closeModal()
    })
}

function setTxTimeout() {
  msgBroadcaster.setOptions({
    txTimeout: new BigNumberInBase(txTimeout.value).dp(0).toNumber()
  })

  notificationStore.success({ title: t('devMode.success') })
  closeModal()
}
</script>

<template>
  <SharedModal v-model="modalStore.modals[Modal.DevMode]" @on:open="resetForm">
    <template #header>
      <h3 class="text-base">
        {{ $t('devMode.devMode') }}
      </h3>
    </template>

    <section>
      <article>
        <CommonInputWrapper
          v-bind="{
            errors: injectiveAddressErrors,
            label: $t('devMode.connectWithAddress')
          }"
        >
          <CommonInput
            v-model="injectiveAddress"
            :placeholder="$t('devMode.enterInjectiveAddress')"
          />
        </CommonInputWrapper>

        <div class="w-full mt-4 text-center">
          <AppButton
            v-bind="{ status, isDisabled: injectiveAddressErrors.length > 0 }"
            @click="connect"
          >
            {{ $t('devMode.connect') }}
          </AppButton>
        </div>
      </article>

      <div class="border-t my-4 border-borderGray" />

      <article>
        <CommonInputWrapper
          v-bind="{
            errors: txTimeoutErrors,
            label: $t('devMode.txTimeout')
          }"
        >
          <CommonInput
            v-model="txTimeout"
            v-bind="{
              type: 'numeric',
              isDisabled: status.isLoading(),
              placeholder: $t('devMode.txTimeout')
            }"
          />
        </CommonInputWrapper>

        <div class="w-full mt-4 text-center">
          <AppButton
            v-bind="{
              isDisabled: txTimeoutErrors.length > 0
            }"
            @click="setTxTimeout"
          >
            {{ $t('devMode.setTxTimeout') }}
          </AppButton>
        </div>
      </article>
    </section>
  </SharedModal>
</template>
