import { TransactionsTableColumn } from '@/types'

export default {
  transaction: {
    table: {
      [TransactionsTableColumn.Time]: 'Time',
      [TransactionsTableColumn.Block]: 'Block',
      [TransactionsTableColumn.Result]: 'Result',
      [TransactionsTableColumn.Amount]: 'Amount',
      [TransactionsTableColumn.TxHash]: 'Tx Hash',
      [TransactionsTableColumn.EventType]: 'Event Type'
    },
    txHash: 'Tx Hash',
    result: 'Result',
    reset: 'Reset',
    block: 'Block',
    failed: 'Failed',
    success: 'Success',
    eventType: 'Event Type',
    selectAll: 'Select All'
  }
}
