<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="logo-icon"
  >
    <mask
      id="mask0_1341_9972"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <path d="M16 0H0V16H16V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1341_9972)">
      <path
        d="M9.68 0.192017L9.632 0.288016C11.296 1.00802 12.24 2.49602 12.24 4.12802C12.24 5.88802 11.088 7.48802 8.848 8.84802L8.48 9.07202C6.8 10.096 5.952 11.248 5.952 12.64C5.952 14.432 7.36 15.744 9.296 15.744C12.4 15.744 16 12.432 16 8.01602C16 7.29602 15.904 6.59202 15.728 5.92002L15.616 5.95202C15.664 6.32002 15.68 6.59202 15.68 6.84802C15.68 9.45602 14.208 11.696 11.744 13.184L11.504 13.328C10.976 13.632 10.528 13.808 10.08 13.808C9.488 13.808 9.04 13.424 9.04 12.864C9.04 12.384 9.36 11.904 10.4 11.296L10.704 11.12C13.008 9.77602 14.288 7.88802 14.288 5.79202C14.288 3.13602 12.304 0.944017 9.68 0.192017ZM6.32 15.824L6.368 15.728C4.704 15.008 3.76 13.52 3.76 11.888C3.76 10.128 4.912 8.52802 7.152 7.16802L7.52 6.94402C9.2 5.92002 10.048 4.76802 10.048 3.37602C10.048 1.58402 8.64 0.272016 6.704 0.272016C3.6 0.272016 0 3.58402 0 8.00002C0 8.72002 0.0960004 9.42402 0.272 10.096L0.384 10.064C0.336 9.69602 0.32 9.42402 0.32 9.16802C0.32 6.56002 1.792 4.32002 4.256 2.83202L4.496 2.68802C5.024 2.38402 5.472 2.20802 5.92 2.20802C6.512 2.20802 6.96 2.59202 6.96 3.15202C6.96 3.63202 6.64 4.11202 5.6 4.72002L5.296 4.89602C2.992 6.24002 1.712 8.12802 1.712 10.224C1.712 12.88 3.696 15.072 6.32 15.824Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>
