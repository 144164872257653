<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { cva, type VariantProps } from 'class-variance-authority'
import { Status, StatusType } from '@injectivelabs/utils'
import { RouteLocationRaw } from '#vue-router'

const buttonClasses = cva(
  [
    'text-center font-medium flex border justify-center items-center outline outline-transparent outline-1 outline-offset-2 transition-all duration-300 font-sans disabled:opacity-50 disabled:cursor-not-allowed rounded'
  ],
  {
    variants: {
      intent: {
        primary:
          'bg-brand-500 border-brand-500 focus:outline-brand-500 hover:bg-brand-600 hover:border-brand-600 active:bg-brand-500 text-white',
        'primary-outline':
          'bg-brand-400/0 border-brand-400 focus:outline-brand-400 hover:bg-brand-400-600/20 active:bg-brand-400/20 text-brand-400',
        'primary-ghost':
          'bg-primary/0 border-transparent focus:outline-primary hover:bg-primary-600/20 active:bg-primary/20 text-primary',
        secondary:
          'bg-gray-400 border-gray-400 focus:outline-gray-400 hover:bg-gray-500 active:bg-gray-400 text-white',
        'secondary-outline':
          'bg-gray-400/0 border-gray-400 focus:outline-gray-400 hover:bg-gray-400/30 active:bg-gray-500/40 text-white',
        success:
          'bg-green-500 border-green-500 focus:outline-green-500 hover:bg-green-600 active:bg-green-500 text-black',
        'success-outline':
          'bg-green-500/0 border-green-500 focus:outline-green-500 hover:bg-green-500/20 active:bg-green-500/20 text-green-500',
        'success-ghost':
          'bg-green-500/0 border-transparent focus:outline-green-500 hover:bg-green-500/20 active:bg-green-500/20 text-green-500',
        danger:
          'bg-red-500 border-red-500 focus:outline-red-500 hover:bg-red-100 active:bg-red-500 text-black',
        'danger-outline':
          'bg-transparent border-red-500 focus:outline-red-500 hover:bg-red-500/20 active:bg-red-400/20 text-red-500',
        white:
          'bg-white border-white focus:white hover:bg-white active:bg-white text-black',
        'white-outline':
          'bg-transparent border-gray-200 focus:white hover:bg-white/10 active:bg-white/20 text-white'
      },
      size: {
        xs: 'py-1 px-3 text-xs',
        sm: 'py-1.5 px-4 text-xs',
        md: 'py-2 px-5 text-sm',
        lg: 'py-3 px-6 text-base'
      }
    }
  }
)

type ButtonProps = VariantProps<typeof buttonClasses>

const props = withDefaults(
  defineProps<{
    class?: string
    status?: Status
    external?: boolean
    isLoading?: boolean
    isDisabled?: boolean
    to?: RouteLocationRaw
    size?: ButtonProps['size']
    intent?: ButtonProps['intent']
  }>(),
  {
    intent: 'primary',
    size: 'md',
    class: '',
    status: () => new Status(StatusType.Idle),
    to: undefined,
    external: false
  }
)

const component = computed(() =>
  props.to ? resolveComponent('NuxtLink') : 'button'
)
</script>

<template>
  <component
    :is="component"
    :disabled="props.isDisabled || status.isLoading()"
    :class="twMerge(buttonClasses({ intent, size, class: props.class }))"
    :to="props.to"
    :external="props.external"
    :target="props.external ? '_blank' : undefined"
  >
    <div
      v-if="status.isLoading() || isLoading"
      class="w-3 h-3 rounded-full border border-current border-l-transparent animate-spin mr-2"
    />
    <slot />
  </component>
</template>
