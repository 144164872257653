<script setup lang="ts">
import { Status, StatusType } from '@injectivelabs/utils'
import * as WalletTracker from '@/app/providers/mixpanel/WalletTracker'
import { Modal, StatusKey } from '@/types'

useHead({
  bodyAttrs: {
    class: 'bg-zinc-50 dark:bg-inj-black dark:text-white overflow-x-hidden'
  }
})

const route = useRoute()
const appStore = useAppStore()
const statsStore = useStatsStore()
const tokenStore = useTokenStore()

const modalStore = useSharedModalStore()
const sharedWalletStore = useSharedWalletStore()
const { $onError } = useNuxtApp()
const { provideStatuses } = useStatusMap()

const status = reactive(new Status(StatusType.Loading))

onMounted(() => {
  provideStatuses(
    Object.values(StatusKey).reduce(
      (list, key) => ({
        ...list,
        [key]: reactive(
          new Status(
            key === StatusKey.ProposalSubmitStatus
              ? StatusType.Idle
              : StatusType.Loading
          )
        )
      }),
      {}
    )
  )

  tokenStore.fetchUnknownTokensList()

  Promise.all([
    sharedWalletStore.init(),
    statsStore.fetchCoinStats(),
    tokenStore.getTokensUsdPriceMap()
  ])
    .catch($onError)
    .finally(() => {
      if (sharedWalletStore.isUserConnected) {
        WalletTracker.trackWalletAddress(sharedWalletStore.injectiveAddress)
      }

      status.setIdle()
    })

  devMode()
})

function devMode() {
  const devModeExistsInQuery =
    route.query.devMode && route.query.devMode === 'true'

  if (!devModeExistsInQuery) {
    return
  }

  appStore.$patch({
    devMode: true
  })

  modalStore.openModal(Modal.DevMode)
}
</script>

<template>
  <AppHocLoading class="h-screen" v-bind="{ status }">
    <NuxtLayout>
      <NuxtPage
        :transition="{
          enterActiveClass: 'transition-all duration-300',
          leaveActiveClass: 'transition-all duration-300',
          enterFromClass: 'opacity-0 translate-y-2 scale-[0.98] blur',
          leaveToClass: 'opacity-0 translate-y-2 scale-[0.98] blur',
          mode: 'out-in'
        }"
      />
    </NuxtLayout>

    <PartialsModalsDevMode />
  </AppHocLoading>
</template>
