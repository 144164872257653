export default defineAppConfig({
  ui: {
    primary: 'ocean',
    gray: 'slate',

    card: {
      background: 'dark:bg-midnight-700'
    },

    button: {
      base: 'transition',
      variant: {
        solid: 'dark:text-white'
      },

      padding: {
        lg: 'py-2.5 px-4'
      }
    },

    dropdown: {
      background: 'dark:bg-midnight-700',
      ring: 'dark:ring-midnight-600'
    },

    badge: {
      variant: {
        solid: 'text-white dark:text-white'
      }
    },

    table: {
      divide: 'dark:divide-gray-800',
      tbody: 'dark:divide-gray-800',
      th: {
        size: 'text-sm',
        font: 'font-normal',
        color: 'text-gray-200',
        base: 'whitespace-nowrap',
        padding: 'py-2 px-1 first:pl-0 last:pr-0'
      },
      td: {
        size: 'text-sm',
        padding: 'py-3 px-2 first:px-0 last:px-0',
        color: 'text-white'
      },
      default: {
        sortButton: {
          class:
            'p-0 text-sm font-normal dark:hover:bg-transparent [&>.iconify]:size-4 dark:text-gray-200'
        }
      }
    },

    selectMenu: {
      option: {
        base: 'cursor-pointer transition',
        padding: 'p-2',
        selectedIcon: {
          base: 'w-4 h-4'
        }
      }
    },

    tooltip: {
      base: '[@media(pointer:coarse)]:hidden px-2 py-1 text-xs h-auto font-normal relative overflow-auto text-wrap'
    },

    input: {
      default: {
        size: 'lg'
      }
    },

    verticalNavigation: {
      base: 'gap-2',
      padding: 'py-2.5 px-4',
      icon: { base: 'w-4 h-4' },
      active: 'dark:bg-brand-500 dark:before:bg-brand-500'
    },

    popover: {
      background: 'dark:bg-midnight-700'
    }
  }
})
